import React from 'react';
import './CustomPagination.css';

const CustomPagination = ({ currentPage, totalPages=3, onPageChange }) => {
  const handlePrev = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      onPageChange(currentPage + 1);
    }
  };

  return (
    <div className="pagination-container">
      <button
        className="pagination-button prev"
        onClick={handlePrev}
        disabled={currentPage === 1}
      >
        Prev
      </button>

      <span className="current-page">Page {currentPage}</span>

      <button
        className="pagination-button next"
        onClick={handleNext}
        disabled={currentPage === totalPages}
      >
        Next
      </button>
    </div>
  );
};

export default CustomPagination;
