import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPrograms } from "../../redux/program/programActions";
import { fetchSubjects } from "../../redux/subject/subjectActions";
import { fetchEvaluationTypes } from "../../redux/evaluationType/evaluationTypeActions";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FaEdit, FaSearch } from "react-icons/fa";
import Loader from "../../components/Loader/Loader";
import AssessmentFilter from "../AssessmentMarking/AssessmentFilter";
import './Exam.css';
import { getProgramWiseSubjectList, getProgramWiseExamList, bulkExamCreate } from "../../services/ExamService";
import { getExamConfigs, getFilteredExamConfigs } from "../../services/ExamConfigService";
import Switch from "../../components/Switch/Switch";
import Form from "react-bootstrap/Form";
import { enqueueSnackbar } from "notistack";
import CustomPagination from "../../components/Pagination/CustomPagination";
import { useNavigate } from "react-router-dom";
import Input from "../../components/Input/Input";

const ExamBulkCreate = () => {
  const [exam_ids, setExamIds] = useState([]);
  const [idx, setIdx] = useState(null);
  const [state, setState] = useState({
    msg: false, loading: true, eventData: [], programData: [],
    subjectData: [], evaluationTypeData: [], program_id: [], subject_id: [],
    evaluation_type_id: [], selectedSubjects: [], selectedProgram: [],
    selctedEvaluationType: [], start: "", end: "", name: "", term: '',
    reset_msg: false, status: "",
    statusData: [
      { id: 1, name: "Not Graded" },
      { id: 2, name: "In Progress" },
      { id: 3, name: "Graded" },
    ],
    current_page: 1, last_page: 1, examList: [], search: false,
    advanceSearch: false,
  });
  const org_id = localStorage.getItem("organization_id");
  const session_id = localStorage.getItem("session_id");
  const permissions = JSON.parse(localStorage.getItem("permissions"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const programData = useSelector((state) => state.program.programs.data);
  const subjectData = useSelector((state) => state.subject.subjects.data);
  const [exam, setExam] = useState([]);
  const evaluationType = useSelector((state) => state.evaluationType.evaluationTypes.data);

  useEffect(() => {
    if (!org_id || !session_id) return;
    dispatch(fetchPrograms());
    dispatch(fetchSubjects({ selected: 0 }));
    dispatch(fetchEvaluationTypes());
    getExamConfigList(1);
  }, [dispatch]);

  const getExamConfigList = async (page) => {
    let current_page = page;
    try {
      setState(prevState => ({ ...prevState, loading: true }));
      const response = await getExamConfigs(session_id, current_page);
      if (response.status === 200) {
        const eventData = response.data.data;
        let last_page = current_page;
        if (eventData.length !== 0) {
          last_page = current_page + 1
        }
        setState(prevState => ({ ...prevState, eventData, current_page, last_page }));
      }
    } catch (error) {
      console.error(error);
    }
    finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const getSubjectList = async (id) => {
    try {
      const response = await getProgramWiseSubjectList(id);
      if (response.status === 200) {
        const subjectData = response.data.data;
        setState(prevState => ({ ...prevState, subjectData }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = async (page) => {
    const {
      start, end, name, program_id, subject_id, evaluation_type_id, status, term
    } = state;
    if (!session_id) return;
    if (term || start || end || name || program_id || subject_id || evaluation_type_id || status) {
      setState(prevState => ({ ...prevState, search: true }));
    }
    setState(prevState => ({ ...prevState, loading: true }));
    try {
      const response = await getFilteredExamConfigs(session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page);
      if (response.status === 200) {
        const eventData = response.data.data;
        let last_page = page;
        if (eventData.length !== 0) {
          last_page = page + 1;
        }
        setState(prevState => ({ ...prevState, eventData, current_page: page, last_page }));
      }
    }
    catch (error) {
      console.error(error);
    }
    finally {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const handleReset = (event) => {
    event.preventDefault();
    setState({
      name: "", program_id: [], subject_id: [],
      evaluation_type_id: [], start: "", end: "",
      reset_msg: true, status: "", selectedProgram: [],
      selectedSubjects: [], selctedEvaluationType: [],
      term: '', search: false, examList: [],
    });
    getExamConfigList(1);
  };

  const getExamList = async (program_id) => {
    setState(prevState => ({ ...prevState, student_loader: true }));
    try {
      const response = await getProgramWiseExamList(program_id);
      if (response.status === 200) {
        const examList = response.data.data;
        setState(prevState => ({ ...prevState, student_loader: false, examList }));
      }
    } catch (error) {
      setState(prevState => ({ ...prevState, disableSubmit: true, student_loader: false }));
      console.error(error);
    }
  };

  const onProgramSelect = (event) => {
    setState(prevState => ({
      ...prevState,
      search: true,
      subject_id: [],
      selectedProgram: event,
      selectedSubjects: [],
      program_id: ["&program_id[]=" + event[event.length - 1].id],
    }));
    getExamList(event[event.length - 1].id);
    getSubjectList(event[event.length - 1].id);
  };

  const onSubjectSelect = (event) => {
    setState(prevState => ({
      ...prevState,
      search: true,
      selectedSubjects: event,
      subject_id: [...prevState.subject_id, "&subject_id[]=" + event[event.length - 1].id],
    }));
  };

  const onEvaluationSelect = (event) => {
    setState(prevState => ({
      ...prevState,
      search: true,
      selctedEvaluationType: event,
      evaluation_type_id: [...prevState.evaluation_type_id, "&evaluation_type_id[]=" + event[event.length - 1].id],
    }));
  };

  const onSubjectRemove = (event) => {
    const subject_id = event.map(item => "&subject_id[]=" + item.id);
    setState(prevState => ({ ...prevState, search: true, selectedSubjects: event, subject_id }));
  };

  const onEvaluationRemove = (event) => {
    const evaluation_type_id = event.map(item => "&evaluation_type_id[]=" + item.id);
    setState(prevState => ({ ...prevState, search: true, selctedEvaluationType: event, evaluation_type_id }));
  };

  const advancedSearchHandler = (e) => {
    e.preventDefault();
    setState(prevState => ({ ...prevState, advanceSearch: !prevState.advanceSearch }));
  };

  const handleInputChange = (event, incomingExam) => {
    const { value, checked } = event.target;
    const exam_id = value;
    let traits_data = [];
    if (incomingExam.is_traits) {
      traits_data = incomingExam.traits && incomingExam.traits.map((trait) => ({
        trait_id: trait.subject_trait.id,
        trait_position: trait.trait_position,
        max_score: trait.max_score,
        grade_scheme_id: trait.grade_scheme.id,
      }));
    }
    let obj = {
      id: exam_id, name: incomingExam.name.id, term: incomingExam.term,
      session_id: session_id, program_id: incomingExam.program.id, subject_id: incomingExam.subject.id,
      evaluation_type_id: incomingExam.evaluation_type.id, max_score: incomingExam.max_score,
      grade_scheme_id: incomingExam.grade_scheme_id, status: 1, is_active: incomingExam.is_active,
      total_attempts: incomingExam.total_attempts, start: incomingExam.start, end: incomingExam.end,
      is_traits: incomingExam.is_traits, attempts: incomingExam.attempts, description: incomingExam.description,
      grade_method: incomingExam.grade_method, traits: traits_data
    };
    if (checked) {
      setExamIds([...exam_ids, exam_id]);
      setExam([...exam, obj]);
    } else {
      const updatedStudentIds = exam_ids.filter((id) => id !== exam_id);
      setExamIds(updatedStudentIds);
      const updatedExam = exam.filter((item) => item.id !== exam_id);
      setExam(updatedExam);
    }
  };

  const selectAllExam = (event) => {
    const exam_ids = [];
    const examData = [];
    if (event.target.checked) {
      eventData.forEach((exam) => {
        const id = exam.id.toString();
        document.getElementById(id).checked = true;
        exam_ids.push(id);
        let traits_data = [];
        if (exam.is_traits) {
          traits_data = exam.traits.map((trait) => ({
            trait_id: trait.subject_trait.id,
            trait_position: trait.trait_position,
            max_score: trait.max_score,
            grade_scheme_id: trait.grade_scheme.id,
          }));
        }
        let obj = {
          id: id, name: exam.name.id, term: exam.term,
          session_id: session_id, program_id: exam.program.id, subject_id: exam.subject.id,
          evaluation_type_id: exam.evaluation_type.id, max_score: exam.max_score,
          grade_scheme_id: exam.grade_scheme_id, status: 1, is_active: exam.is_active,
          total_attempts: exam.total_attempts, start: exam.start, end: exam.end, grade_method: exam.grade_method,
          is_traits: exam.is_traits, attempts: exam.attempts, description: exam.description, traits: traits_data
        };
        examData.push(obj);
      });
    } else {
      eventData.forEach((student) => {
        const id = student.id;
        document.getElementById(id).checked = false;
      });
    }
    setExam(examData);
    setExamIds(exam_ids);
  };

  const changeHandler = (e) => {
    const { name, value } = e.target;

    setState(prevState => ({ ...prevState, search: true, [name]: value }));
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    console.log(exam_ids, "exam_ids", exam);
    if (exam_ids.length === 0) {
      enqueueSnackbar("Please select at least one exam", { variant: "error" });
      return;
    }
    try {
      const response = await bulkExamCreate(exam);
      console.log(response, "response");
      if (response.status === 200) {
        enqueueSnackbar("Exam created successfully", { variant: "success" });
        setExam([]);
        navigate("/exam/list");
      }
    }
    catch (error) {
      console.error(error);
      enqueueSnackbar("Failed to create exam", { variant: "error" });
    };
  }
  const inputChangeHandler = (e, index) => {
    const { name, value } = e.target;
    const updatedExam = [...state.eventData];
    console.log(updatedExam, state.eventData, "updatedExam", name, value);
    updatedExam[index][name] = value;
    // setExam(updatedExam);
    setState(prevState => ({ ...prevState, eventData: updatedExam }));
  };
  const { advanceSearch, eventData, last_page, search, current_page, loading } = state;

  return (
    <div className="card">
      {state.loading ? (
        <Loader />
      ) : (
        <div className="">
          <div className="card-header">
            <div className="d-flex justify-content-between">
              <div className="page-title d-flex align-items-center">
                <Button className="btn-success" onClick={advancedSearchHandler}>
                  <FaSearch /> Search
                </Button>
              </div>
                <Link className="btn btn-primary" to="/exam/list">Back</Link>
            </div>
          </div>

          <div className="card-body">
            <div className={`filter-event ${advanceSearch ? 'show' : 'hide'} mb-5`}
              id="advanced-search">
              <AssessmentFilter
                state={state}
                evaluationType={evaluationType}
                programData={programData}
                subjectData={subjectData}
                changeHandler={changeHandler}
                onProgramSelect={onProgramSelect}
                onSubjectSelect={onSubjectSelect}
                onEvaluationSelect={onEvaluationSelect}
                onSubjectRemove={onSubjectRemove}
                onEvaluationRemove={onEvaluationRemove}
                handleSearch={handleSearch}
                handleReset={handleReset}
              />
            </div>
            <Form onSubmit={submitHandler}>
              {eventData && eventData.length > 0 ? (
                <div className="evaluating-table event-tablecus">
                  <div className="row mt-2">
                    <div className="col-md-12">
                      {
                        loading ?
                          <Loader />
                          :
                          <Form.Group>
                            <table className="table table-hover table-bordered">
                              <thead>
                                <tr>
                                  <th>SrNo</th>
                                  <th>Name</th>
                                  <th>Term</th>
                                  <th>Class</th>
                                  <th>Subject</th>
                                  <th>EvaluationType</th>
                                  <th>Max Score</th>
                                  <th>Start</th>
                                  <th>End</th>
                                  <th>
                                    <Switch
                                      //   checked={(props.exam_ids.length ===
                                      //     (props.data.studentList ? props.data.studentList.length : null))
                                      //     && props.data.studentList.length > 0 ? true : false}
                                      name="all"
                                      id="select-all"
                                      value="1"
                                      onChange={e => selectAllExam(e)}
                                      label={'Select All'}
                                    />
                                  </th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody className="col-md-12">
                                {
                                  eventData && eventData.map((exam, index) =>
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{exam.name.name}</td>
                                      <td>{exam.term}</td>
                                      <td>{exam.program.name}</td>
                                      <td>{exam.subject.name}</td>
                                      <td>{exam.evaluation_type.name}</td>
                                      <td>{idx === index ?
                                        <Input
                                          type="number"
                                          name="max_score"
                                          // value={exam.max_score}
                                          onChangeHandler={(e) => inputChangeHandler(e, index)}
                                        />
                                        : exam.max_score}
                                      </td>
                                      <td>
                                        {idx === index ?
                                          <Input
                                            type="date"
                                            name="start"
                                            value={exam.start}
                                            onChangeHandler={(e) => inputChangeHandler(e, index)}
                                          /> : exam.start}</td>
                                      <td>
                                        {idx === index ?
                                          <Input
                                            type="date"
                                            name="end"
                                            value={exam.end}
                                            onChangeHandler={(e) => inputChangeHandler(e, index)}
                                          /> : exam.end}
                                      </td>
                                      <td>
                                        <div className="form-check form-check-inline">
                                          {eventData.length &&
                                            eventData.indexOf(exam.id.toString()) !== -1 ?
                                            <Switch
                                              name="exam_id"
                                              id={exam.id}
                                              checked={eventData.indexOf(exam.id.toString()) !== -1 ? true : false}
                                              value={exam.id || ''}
                                              onChange={e => handleInputChange(e, exam)}
                                            /> :
                                            <Switch
                                              name="student_id"
                                              id={exam.id}
                                              value={exam.id || ''}
                                              onChange={(e) => handleInputChange(e, exam)}
                                            />
                                          }
                                        </div>
                                      </td>
                                      <td>
                                        <Button
                                          className="btn btn-primary"
                                          onClick={() => setIdx(index)}
                                        >
                                          <FaEdit />
                                        </Button>
                                      </td>
                                    </tr>)}
                                {eventData.length === 0 ?
                                  <tr align="center"><th colSpan="3">No Record Found</th></tr> : null}
                              </tbody>
                            </table>
                          </Form.Group>}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center"> No data found</div>
              )}
              <div className="d-flex justify-content-end">
                <Button type="submit" className="btn btn-primary" onClick={submitHandler}>
                  Create
                </Button>
              </div>
            </Form>
          </div>
        </div>
      )}
      {eventData && eventData.length > 0 && (
        <div className="card-footer">
          <CustomPagination
            currentPage={current_page}
            totalPages={last_page}
            onPageChange={(i) => search ? handleSearch(i) : getExamConfigList(i)}
          />
        </div>
      )}
    </div>
  );
};

export default ExamBulkCreate;
