import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { FaBolt } from "react-icons/fa";
import LeaveApproval from "./LeaveApproval";
import Loader from "../../../components/Loader/Loader";
import { enqueueSnackbar } from "notistack";
import LeaveFilter from "./LeaveFilter";
import CardHeader from "../../../components/Card/CardHeader";
import Pagination from "../../../components/Pagination/Pagination";
import { getLeaves, leaveApproval } from "../../../services/AppManagement/LeaveService";
import { getMappedPrograms } from "../../../services/AppManagement/AttendanceService";

const LeaveList = () => {
  const [data, setData] = useState([]);
  const [program_id, setProgramId] = useState('');
  const [leave_date, setLeaveDate] = useState('');
  const permissions = JSON.parse(localStorage.getItem('permissions'));
  const [status, setStatus] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [leaveApprovalPrograms, setLeaveApprovalProgram] = useState([]);
  const [leaveIDs, setLeaveIDs] = useState([]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    if (name === 'program_id') {
      setProgramId(value);
    }
    if (name === 'leave_date') {
      setLeaveDate(value);
    }
    if (name === 'status') {
      setStatus(value);
    }
  }

  const fetchMappedPrograms = async (id) => {
    try {
      const response = await getMappedPrograms(id);
      if (response.status === 200) {
        let approverPrograms = response.data.data.leave_approver.map((item) => item.program);
        setLeaveApprovalProgram(approverPrograms);
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
  };

  async function fetchLeave({ selected }) {
    const session_id = localStorage.getItem('session_id');
    setCurrentPage(selected);
    const page = selected + 1;
    try {
      setIsLoader(true);
      const response = await getLeaves(program_id, session_id, leave_date, status, page);
      if (response.status === 200) {
        setData(response.data.data.data);
        setLastPage(response.data.data.last_page);
      }
      else {
        enqueueSnackbar("Leave Fetching Failed", { variant: 'error' });
        setData([]);
        return;
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
    }
    finally {
      setIsLoader(false);
    }
  }
  useEffect(() => {
    const user_id = JSON.parse(localStorage.getItem('user')).id;
    fetchMappedPrograms(user_id);
    fetchLeave({ selected: 0 });
  }, []);

  const leaveApproverHandler = async (e, status) => {
    e.preventDefault();
    const session_id = localStorage.getItem('session_id');
    const data = {
      leave_ids: leaveIDs,
      status: status,
      session_id: session_id
    };
    try {
      setIsLoader(true);
      const response = await leaveApproval(data);
      if (response.status === 200) {
        enqueueSnackbar("Leave Verified Successfully", { variant: 'success' });
        setLeaveIDs([]);
        fetchLeave({ selected: 0 });
      }
      else {
        enqueueSnackbar("Leave Verification Failed", { variant: 'error' });
      }
    }
    catch (error) {
      console.error("API Error:", error.response);
      setIsLoader(false);
    }
    finally {
      setIsLoader(false);
    }
  }

  const selectLeaveHandler = (event) => {
    const value = event.target.value;
    let updatedLeaveIds;

    if (event.target.checked) {
      updatedLeaveIds = [...leaveIDs, value];
    } else {
      updatedLeaveIds = leaveIDs.filter(id => id !== value);
    }
    setLeaveIDs(updatedLeaveIds);
  };

  const selectAllLeaveHandler = (event) => {
    const updatedLeaveIds = event.target.checked
      ? data.map(leave => leave.id.toString())
      : [];
    setLeaveIDs(updatedLeaveIds);
  };


  return (
    <div className="animated">
      <div className="card">
        <CardHeader
          title="LeaveList"
          icon={<FaBolt />}
          button={
            permissions.includes('leave.create') &&
            <Link to="/leave/create" className="btn btn-primary">Add Leave</Link>
          }
        />
        <div className="card-body">
          <LeaveFilter
            changeHandler={changeHandler}
            fetchLeave={fetchLeave}
            programData={leaveApprovalPrograms}
            program_id={program_id}
            leave_date={leave_date}
            status={status}
          />
          {isLoader ? <Loader /> :
            <div className="row mt-3">
              {data && (
                <React.Fragment>
                  <LeaveApproval
                    data={data}
                    selectAllLeaveHandler={selectAllLeaveHandler}
                    selectLeaveHandler={selectLeaveHandler}
                    leaveApproverHandler={leaveApproverHandler}
                    leaveIDs={leaveIDs}
                    permissions={permissions}
                    isLoader={isLoader}
                  />
                  <Pagination
                    totalPage={lastPage}
                    currentPage={currentPage}
                    handlePageChange={(i) => fetchLeave(i)}
                  ></Pagination>
                </React.Fragment>
              )}
            </div>}
        </div>
      </div>
    </div>
  );
};
export default LeaveList;
