import axios from 'axios';
import { ENDPOINTS } from './endpoints';

export const getExamConfigs = async (session_id, page) => {
  try {
    const response = await axios.get(ENDPOINTS.EXAM_CONFIG.GET, { params: { session_id, page } });
    return response;
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
};

export const getFilteredExamConfigs = async (session_id, name, program_id, subject_id, evaluation_type_id, start, end, term, status, page) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM_CONFIG.GET_FILTERED}`, {
      params: {
        session_id: session_id,
        query: name,
        program_id: program_id,
        subject_id: subject_id,
        evaluation_type_id: evaluation_type_id,
        start: start,
        end: end,
        term: term,
        status: status,
        page: page
      }
    });
    return response; // Assuming the API returns the list of users directly
  } catch (error) {
    // Handle errors or re-throw them
    throw error;
  }
}

export const getExamConfigById = async (id) => {
  try {
    const response = await axios.get(`${ENDPOINTS.EXAM_CONFIG.GET_EXAM_CONFIG}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

export const createExamConfig = async (data) => {
  try {
    const response = await axios.post(ENDPOINTS.EXAM_CONFIG.CREATE, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateExamConfig = async (id, data) => {
  try {
    const response = await axios.put(`${ENDPOINTS.EXAM_CONFIG.UPDATE}/${id}`, data);
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteExamConfig = async (id) => {
  try {
    const response = await axios.delete(`${ENDPOINTS.EXAM_CONFIG.DELETE}/${id}`);
    return response;
  } catch (error) {
    throw error;
  }
};

